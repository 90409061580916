<template>
  <div>
    <b-navbar sticky class="bg-light">
      <b-container>
        <b-navbar-nav>
          <b-nav-text>number of files（{{ file_path_list.length ? fileIndex + 1 : 0 }}/{{ file_path_list.length }}）</b-nav-text>
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-nav-item @click="last">Last</b-nav-item>
          <b-nav-item @click="next">Next</b-nav-item>
        </b-navbar-nav>
      </b-container>
    </b-navbar>

    <div class="w-100" v-if="footer">
      <pdf v-for="i in numPages" :key="i" :src="src" height="100%" width="100%" :page="i"></pdf>
    </div>

    <!-- 成功 -->
    <div v-if="sucess" style="text-align: center">
      <img src="../stactic/image/sucess.png" alt="" />
      <div class="res">Completed</div>
    </div>
    <!-- 失败 -->
    <div v-if="error" style="text-align: center">
      <img src="../stactic/image/error.png" alt="" />
      <div class="res">{{ error_content }}</div>
    </div>

    <div class="fixed-bottom bg-light py-3">
      <b-container>
        <div v-if="footer">
          <div class="form-check form-check-inline">
            <input :disabled="checkoutDisabled" class="form-check-input" type="checkbox" id="agreeCheckbox" v-model="checkbox" />
            <label class="form-check-label" for="agreeCheckbox">Have read and agreed to the agreement</label>
          </div>
          <div class="mt-3 text-center">
            <button @click="submit" :disabled="disabled" type="button" class="btn btn-outline-primary">Sign immediately</button>
          </div>
        </div>
        <div v-else>
          <div class="d-flex justify-center">
            <button @click="closePage" class="btn">Close page</button>
          </div>
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
import pdf from "vue-pdf"
export default {
  components: { pdf },
  data() {
    return {
      checkbox: false,
      sucess: false,
      error: false,
      footer: true,
      // 订单编号
      order_no: "",
      error_content: "",
      fileData: null,
      file_path_list: [],
      fileIndex: 0,
      checkoutDisabled: true,

      src: null,
      numPages: null,
    }
  },
  created() {
    this.$toasted.show(
      "1.Please read the document before signing the agreement；<br>2.Please sign the legal name；<br><br>Getting signed documents, please be patient...",
      { position: "top-center" }
    )
    const order_no_key = Object.keys(this.$route.query)[0]
    this.order_no = this.$route.query[order_no_key]
    this.getSignatureFileList(this.order_no)
  },
  computed: {
    disabled() {
      if (this.checkbox) {
        return false
      } else {
        return true
      }
    },
  },
  watch: {
    fileIndex(n) {
      this.pdf(n)
    },
  },
  methods: {
    // 上一个
    last() {
      if (this.fileIndex == 0) return
      this.fileIndex = this.fileIndex - 1
    },
    // 下一个
    next() {
      if (this.fileIndex == this.file_path_list.length - 1) return
      this.fileIndex = this.fileIndex + 1
    },

    async getSignatureFileList(order_no) {
      const res = await this.$axios.get(`/signature/signatureFileList?order_no=${order_no}`)
      // console.log('res',res)
      if (res.data.code == 200) {
        this.$toasted.clear()
        // 文件展示
        this.fileData = res.data.data.list
        this.fileData.forEach((item) => {
          this.file_path_list.push(item.file_path)
        })

        this.pdf(this.fileIndex)
        this.$toasted.show("Get file complete", { duration: 3000, position: "top-center" })

        this.checkoutDisabled = false
      } else {
        this.$toasted.clear()
        this.$toasted.show(res.data.msg, { duration: 3000, position: "top-center" })
      }
    },
    pdf(link) {
      this.src = pdf.createLoadingTask(this.file_path_list[link])
      this.src.promise
        .then((pdf) => {
          this.numPages = pdf.numPages
        })
        .catch((e) => e)
    },
    // 签署
    async submit() {
      this.$router.push({
        path: "/signature_en",
        query: {
          order_no: this.order_no,
          file_list: JSON.stringify(this.fileData),
        },
      })
    },
    closePage() {
      window.location.href = "about:blank"
    },
  },
}
</script>

<style>
body {
  background-color: #ffffff !important;
}
</style>
