<template>
  <div class="box">
    <!-- <div class="aaa"></div> -->
    <div class="footer" v-if="signature">
      <!-- <div><button @click="canvasClear" class="button">清除</button></div> -->
      <!-- <div class="d-flex"><button @click="saveAsImg" class="button">提交（签署法人中文拼音名字）</button></div> -->
      <!-- <div><button @click="saveAsImg"  type="button" class="btn btn-primary">提交（签署法人中文拼音名字）</button></div> -->
      <div><button @click="canvasClear" class="btn btn-light" :disabled="sub_disabled" style="width:250px;height: 50px;">清除</button></div>
      <div>
        <button @click="saveAsImg" type="button" :disabled="sub_disabled" class="btn btn-secondary" style="width: max-content;height: 50px;">提交（签署法人中文拼音名字）</button>
      </div>
    </div>

    <div v-if="signature">
      <sign-canvas class="canvas" ref="SignCanvas" :options="options" v-model="value" />
    </div>

    <!-- 成功 -->
    <div v-if="sucess" class="result">
      <img src="../stactic/image/sucess.png" alt="" />
      <div class="res">签署完成</div>
    </div>
    <!-- <b-modal id="Confirm"></b-modal> -->
    <Confirm ref="Confirm"></Confirm>
    <Hint ref="Hint"></Hint>
  </div>
</template>

<script>
import SignCanvas from "sign-canvas"
import basetoFile from "../stactic/js/basetoFile"
// 确认框
import Confirm from "../components/Confirm.vue"
import Hint from "../components/Hint.vue"
import rotateBase64Img from "../stactic/js/edg"
export default {
  name: "dev",
  components: { SignCanvas, Confirm, Hint },
  data() {
    return {
      snackbar: false,
      snackbarText: null,
      value: null,
      options: {},
      // 订单 编号
      order_no: "",
      // 定单的 文件lsit  json
      file_list: "",
      // 电子签名地址
      signature_path: "",
      signature: true,
      sucess: false,
      // error:false,
      // error_content:''
      // 初始画布 size
      initCanvasSize: "",
      sub_disabled: false,
    }
  },
  watch: {},

  async mounted() {
    // 获取 order_no json file_list
    this.order_no = this.$route.query.order_no
    this.file_list = this.$route.query.file_list
    // 设置画布
    await this.setCanvas()
    // 通过初始化的图片大小判断 是否签字
    this.initCanvasSize = basetoFile(this.$refs.SignCanvas.saveAsImg(), "file").size
    console.log('初始大小：',this.initCanvasSize);
  },
  methods: {
    setCanvas() {
      //  设置画布
      const screenWidth = window.screen.width
      const screenHeight = window.screen.height
      const options = {
        isDpr: false, //是否使用dpr兼容高倍屏 [Boolean] 可选
        lastWriteSpeed: 1, //书写速度 [Number] 可选
        lastWriteWidth: 2, //下笔的宽度 [Number] 可选
        lineCap: "round", //线条的边缘类型 [butt]平直的边缘 [round]圆形线帽 [square]	正方形线帽
        lineJoin: "bevel", //线条交汇时边角的类型  [bevel]创建斜角 [round]创建圆角 [miter]创建尖角。
        canvasWidth: screenWidth, //canvas宽高 [Number] 可选
        canvasHeight: document.documentElement.clientHeight, //高度  [Number] 可选
        isShowBorder: false, //是否显示边框 [可选]
        // bgColor: "#fcc", //背景色 [String] 可选 
        bgColor: "transparent", //背景色 [String] 可选 transparent
        borderWidth: -1, // 网格线宽度  [Number] 可选
        borderColor: "#ff787f", //网格颜色  [String] 可选
        writeWidth: 5, //基础轨迹宽度  [Number] 可选
        maxWriteWidth: 30, // 写字模式最大线宽  [Number] 可选
        minWriteWidth: 5, // 写字模式最小线宽  [Number] 可选
        writeColor: "#101010", // 轨迹颜色  [String] 可选
        isSign: true, //签名模式 [Boolean] 默认为非签名模式,有线框, 当设置为true的时候没有任何线框
        imgType: "png", //下载的图片格式  [String] 可选为 jpeg  canvas本是透明背景的
      }
      this.options = options
    },
    canvasClear() {
      this.$refs.SignCanvas.canvasClear()
    },

    //提交
    async saveAsImg() {
      console.log('目前大小',basetoFile(this.$refs.SignCanvas.saveAsImg(), "file").size);
      // 判断是否签了字
      const s = basetoFile(this.$refs.SignCanvas.saveAsImg(), "file").size
      if (this.initCanvasSize == s) {
        this.$refs.Hint.confirm()
        return
      }
      this.sub_disabled = true
      this.$refs.Confirm.confirm()
        .then(async () => {
          const img = this.$refs.SignCanvas.saveAsImg()
          const file = basetoFile(img, "file")
          // 开始上传签名图片
          let formdata = new FormData()
          formdata.append("file", file)
          const res = await this.$axios.post("/commonUploadFiles", formdata)
          if (res.data.code == 200) {
            // 电子签名地址
            this.signature_path = res.data.data[0]
            // 提交电子签名
            const r = await this.$axios.post("/signature/addSignature", {
              order_no: this.order_no,
              signature_path: this.signature_path,
              file_list: this.file_list,
            })
            if (r.data.code == 200) {
              // 直接展示成功
              this.signature = false
              this.sucess = true
            } else {
              alert(r.data.msg)
              this.sub_disabled = false
            }
          } else {
            alert(res.data.msg)
            this.sub_disabled = false
          }
        })
        .catch((e) => {
          this.sub_disabled = false
        })
    },

    /**
     * 下载图片
     */
    // downloadSignImg() {
    //   this.$refs.SignCanvas.downloadSignImg();
    // }
  },
}
</script>

<style scoped>
.box {
  width: 100vw;
  height: 100vh;
  display: flex;
}

.footer {
  width: 100px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: gray;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.footer > div {
  transform: rotate(90deg);
}

.button {
  width: 300px;
  height: 50px;
}

.result {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
