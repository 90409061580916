<template>
  <div>
    <!-- <button class="btn btn-secondary" @click="aaa">你好</button>
      <div><button @click="saveAsImg"  type="button" :disabled="false" class="btn btn-secondary">提交（签署法人中文拼音名字）</button></div> -->
  </div>
</template>

<script>
  export default {
    data(){
      return {
        loading: false
      }
    },
    methods: {
      aaa(){
        this.loading = true
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>